<template>
  <div>
    <vocabulary></vocabulary>
  </div>
</template>
<script>
import vocabulary from "@/components/vocabulary.vue";
export default {
  components: {
    vocabulary
  },
  computed: {},
  watch: {},
  data () {
    return {
    };
  },
  mounted () {
  },
  methods: {

  },
  beforeDestroy () {
  }
};
</script>
<style lang="less" scoped>
</style>
