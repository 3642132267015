<template>
  <div class="vocabulary">
    <div>
      <van-empty
        v-if="isCompeleteTask == 1"
        description="恭喜你已完成今日打卡"
      />
      <div v-if="show == 1 && route.type == 1">
        <div class="top">
          <div>
            <span>今日复习</span>
            <span>{{ toDayCheck }}/{{ taksCheckWords }}</span>
          </div>
          <div>
            <span>今日新词</span>
            <span>{{ toDayNew }}/{{ taskNewsWords }}</span>
          </div>
          <div>
            <span>学习时间</span>
            <span>{{ studyDuration }}</span>
          </div>
        </div>
        <van-progress
          pivot-text=" "
          color="#3A78F9"
          track-color="#BCD1FC"
          :percentage="percentage"
        />
      </div>
      <div class="cont" v-if="(show == 1 || show == 2) && studys.length > 0">
        <div class="danc">
          <div>{{ studys[index].Name }}</div>
          <div>
            <img
              src="@/assets/images/icon_audio.png"
              class="icon_audio"
              alt=""
              srcset=""
              @click="play(studys[index].Name)"
            />
            {{ studys[index].Phonetic }}
          </div>
        </div>
        <div class="btn_que">
          <div v-for="(an, i) in answer" :key="i" @click="answerClick(an, i)">
            {{ an }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="show == 3">
      <div class="word bgc_ffffff">
        <div class="name">{{ studys[index].Name }}</div>
        <div class="pronunciation">
          {{ studys[index].Phonetic }}
          <img
            src="@/assets/images/icon_audio.png"
            class="icon_audio"
            alt=""
            srcset=""
            @click="play(studys[index].Name)"
          />
        </div>
        <p class="notes" v-html="studys[index].Explain"></p>
      </div>
      <div class="cont">
        <div class="li bgc_ffffff" v-if="studys[index].Example">
          <div class="title">例句</div>
          <div v-html="studys[index].Example"></div>
        </div>
        <div class="li bgc_ffffff" v-if="studys[index].Derivation">
          <div class="title">派生词</div>
          <div v-html="studys[index].Derivation"></div>
        </div>
        <div class="li bgc_ffffff" v-if="studys[index].WordExtend">
          <div class="title">扩展</div>
          <div v-html="studys[index].WordExtend"></div>
        </div>
      </div>
      <div class="btn_que" v-if="route.type != 3">
        <!-- <div class="act" @click="prev">上一个</div> -->
        <div @click="next">下一个</div>
      </div>
      <!-- <div class="btn_que">
        <div @click="goHistory">返回上一页</div>
      </div> -->
    </div>

    <audio ref="audio"></audio>
  </div>
</template>
<script>
import { Dialog, Empty } from "vant";
import { queryStudySituation } from "@/api/questionBank";
import {
  queryWordsTask,
  taskWordsSave,
  queryEnglishWord,
  saveWordsRecord,
  queryWordsById,
  queryNoMasterWords,
} from "@/api/questionBank";
export default {
  components: {},
  computed: {},
  watch: {},
  data() {
    return {
      answer: ["认识", "不认识", "模糊"],
      value: "",
      show: 0,
      studys: [],
      index: 0,
      newstudys: [],
      checktudys: [],
      noMasterWords: [],
      isStartNoMaster: 0,
      toDayNew: 0, // 今日新词
      toDayCheck: 0, // 今日复习
      percentage: 0, // 进度条
      taskNewsWords: 0,
      taksCheckWords: 0,
      studyDuration: "00:00:00",
      time: "",
      hour: 0, //定义时，分，秒，毫秒并初始化为0；
      minute: 0, //分
      second: 0, //秒
      history: false,
      check: false, //查询是否有做题数据,
      isCompeleteTask: 0,
      taskNowAnswer: 0,
      masterWordsCount: 0,
    };
  },
  mounted() {
    // title  立即学习（type=1）  真题词汇（type=2）  生词本（type=3）【未掌握、已掌握】
    this.route = this.$route.query;
    this.show = this.route.type;
    document.title = this.route.title;
    if (this.route.type == 1) {
      this.getQueryStudySituation();
      this.time = setInterval(this.timer, 1000);
    } else if (this.route.type == 2) {
      this.postQueryEnglishWord();
    } else if (this.route.type == 3) {
      this.getQueryWordsById();
    }
  },
  methods: {
    // 查询学习信息
    async getQueryStudySituation() {
      let parm =
        "?courseId=" + this.route.courseId + "&studentId=" + this.route.UserID;
      const res = await queryStudySituation(parm);
      if (res.success == true) {
        if (res.response.todayTask) {
          this.toDayNew = res.response.todayTask.NewProgress;
          this.toDayCheck = res.response.todayTask.CheckProgress;
          this.taskNewsWords = res.response.todayTask.NewWordsCount;
          this.taksCheckWords = res.response.todayTask.CheckWordsCount;
          this.check = true;
        }
        this.getQueryWordsTask();
      }
    },
    goHistory() {
      this.$router.go(-1);
    },
    // 英语单词按分类查询
    async getQueryWordsById() {
      let parm = "?wordId=" + this.route.Id;
      const res = await queryWordsById(parm);
      if (res.success === true) {
        this.show = 3;
        this.index = 0;
        this.studys = [res.response];
      }
    },
    // 英语单词按分类查询
    async postQueryEnglishWord() {
      let parm = {
        studentId: this.route.UserID,
        courseId: this.route.courseId,
        wordsCategoryId: this.route.CategroryId,
        pageIndex: 1,
        pageSize: 1,
      };
      const res = await queryEnglishWord(parm);
      if (res.success === true) {
        if (res.response.data.length <= 0) {
          this.$message.warning("当前暂无更多答题");
          this.history = true;
        } else {
          this.show = 2;
          this.index = 0;
          this.studys = res.response.data;
        }
        // if (res.response.data.length <= 0) {
        //   this.$message.warning("当前暂无更多答题");
        //   this.history = true;
        // }
      }
    },
    async queryNoRenshids() {
      let parm =
        "?studentId=" + this.route.UserID + "&taskId=" + this.route.taskId;
      const res = await queryNoMasterWords(parm);
      res.response.forEach((element) => {
        element.isNew = element.IsCheck == 1 ? 0 : 1;
      });
      this.noMasterWords = res.response;
      if (this.noMasterWords.length > 0) {
        //所有新词打完的情况
        Dialog.alert({
          message:
            "需要将所有单词认识之后才能完成今日任务，下面将为你展示你不认识的单词",
          messageAlign: "left",
        }).then(() => {
          this.studys = this.noMasterWords;
          this.index = 0;
          this.show = this.route.type;
          this.noMasterWords = [];
          this.isStartNoMaster = 1;
        });
      } else {
        //所有新词打完的情况
        Dialog.alert({
          message: "恭喜你已完成今日任务",
        }).then(() => {
          this.show = -1;
          // this.isCompeleteTask = 1;

          let url = "/H5/questionCenter/punchCalendar";
          let obj = {
            UserID: this.route.UserID,
            courseId: this.route.courseId,
          };
          this.$router.push({ path: url, query: obj });
          this.noMasterWords = [];
          this.isStartNoMaster = 0;
        });
        this.index = this.studys.length - 1;
      }
    },
    prev() {
      if (this.index == 0) {
        this.index = 0;
      } else {
        this.index--;
        this.show = this.route.type;
      }
      for (let inex = 0; inex < this.studys.length; inex++) {
        if (this.index <= inex) {
          if (this.studys[inex].isNew == 0) {
            this.toDayCheck++;
          } else {
            this.toDayNew++;
          }
          break;
        }
      }
      this.percentage = ((this.index + 1) / this.studys.length) * 100;
    },
    next() {
      if (this.route.type == 1) {
        if (this.studys[this.index].isNew == 0) {
          if (this.taskNowAnswer == 0) {
            this.toDayCheck++;
          }
        } else {
          if (this.taskNowAnswer == 0) {
            this.toDayNew++;
          }
        }

        if (this.index == this.studys.length - 1) {
          this.queryNoRenshids();

          // this.$message.warning("需要将所有单词认识之后才能完成今日任务，");
        } else {
          this.index++;
          this.show = this.route.type;
        }

        // if (this.isStartNoMaster == 0) {
        // for (let inex = 0; inex < this.studys.length; inex++) {
        //   if (this.index <= inex) {
        //     if (this.studys[inex].isNew == 0) {
        //       if (this.taskNowAnswer == 0) {
        //         this.toDayCheck++;
        //       }
        //     } else {
        //       if (this.taskNowAnswer == 0) {
        //         this.toDayNew++;
        //       }
        //     }
        //     break;
        //   }
        // }
        this.percentage =
          (this.masterWordsCount / (this.taksCheckWords + this.taskNewsWords)) *
          100;
        // }
      } else if (this.route.type == 2) {
        this.postQueryEnglishWord();
      } else {
      }
    },
    //播放词语
    play(name) {
      this.$refs.audio.src = "https://dict.youdao.com/speech?audio=" + name;
      this.$refs.audio.play();
    },
    answerClick(an, i) {
      if (this.route.type == 1) {
        this.taskNowAnswer = i;
        if (i == 0) {
          this.masterWordsCount++;
        }
        this.postTaskWordsSave(an, i);
      } else if (this.route.type == 2) {
        this.postSaveWordsRecord(an, i);
      }
    },
    // 英语单词保存
    async postSaveWordsRecord(an, i) {
      let parm = {
        studentId: this.route.UserID,
        courseId: this.route.courseId,
        wordsCategoryId: this.route.CategroryId,
        answer: i,
        wordId: this.studys[this.index].Id,
      };
      const res = await saveWordsRecord(parm);
      if (res.success === true) {
        this.show = 3;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 保存答题
    async postTaskWordsSave(an, i) {
      // if (i != 0) {
      //   //将不认识的题目存入到数组中
      //   this.noMasterWords.push(this.studys[this.index]);
      // }
      let parm = {
        studentId: this.route.UserID,
        wordId: this.studys[this.index].Id,
        courseId: this.route.courseId,
        answer: i,
        taskId: this.route.taskId,
        wordsCategoryId: this.studys[this.index].WordsCategoryId,
        isNew: this.isStartNoMaster == 1 ? -1 : this.studys[this.index].isNew,
        IsCheck: this.studys[this.index].IsCheck,
        isCompelete: this.index + 1 == this.studys.length ? 1 : 0,
        StudyDuration: this.second + this.minute * 60 + this.hour * 60 * 60,
      };

      const res = await taskWordsSave(parm);
      if (res.success === true) {
        this.show = 3;
      } else {
        this.$message.error(res.msg);
      }
    },
    timer() {
      //定义计时函数
      this.second = this.second + 1; //秒
      if (this.second >= 60) {
        this.second = 0;
        this.minute = this.minute + 1; //分钟
      }
      if (this.minute >= 60) {
        this.minute = 0;
        this.hour = this.hour + 1; //小时
      }
      this.studyDuration =
        this.toDub(this.hour) +
        ":" +
        this.toDub(this.minute) +
        ":" +
        this.toDub(this.second); /*+""+this.toDubms(this.ms)+"毫秒"*/
    },
    toDub(n) {
      //补0操作
      if (n < 10) {
        return "0" + n;
      } else {
        return "" + n;
      }
    },
    // 查询
    async getQueryWordsTask() {
      let parm = {
        taskId: this.route.taskId,
        studentId: this.route.UserID,
        courseId: this.route.courseId,
        newWordsCount: this.route.newWordsCount,
        checkWordsCount: this.route.checkWordsCount,
      };
      const res = await queryWordsTask(parm);
      if (res.success === true) {
        //复习词任务数量显示
        if (
          res.response.checkWords.length + res.response.CheckProgress <
          res.response.taskCheckCount
        ) {
          this.taksCheckWords =
            res.response.checkWords.length + res.response.CheckProgress;
        } else {
          this.taksCheckWords = res.response.taskCheckCount;
        }

        //新词显示数量
        if (
          res.response.newWords.length + res.response.NewProgress <
          res.response.taskNewsCount
        ) {
          this.taskNewsWords =
            res.response.newWords.length + res.response.NewProgress;
        } else {
          this.taskNewsWords = res.response.taskNewsCount;
        }

        //今日已经掌握的新词
        this.toDayNew = res.response.NewProgress - res.response.noMasterNewWord;
        //今日已经掌握的复习词
        this.toDayCheck =
          res.response.CheckProgress - res.response.noMasterCheckWord;

        this.masterWordsCount = this.toDayNew + this.toDayCheck;

        //新词和复习词标识 isNew是否是新词 0 1
        res.response.checkWords.forEach((element) => {
          element.isNew = 0;
          element.IsCheck = 1;
        });
        res.response.newWords.forEach((element) => {
          element.isNew = 1;
          element.IsCheck = 0;
        });

        res.response.noMasterWords.forEach((element) => {
          element.isNew = element.IsCheck == 1 ? 0 : 1;
        });

        if (
          res.response.isCompelete == 0 &&
          res.response.newWords.length == 0 &&
          res.response.checkWords.length == 0 &&
          (res.response.NewProgress != 0 || res.response.CheckProgress != 0)
        ) {
          //说明还有不认识的单词
          this.studys = res.response.noMasterWords;
          this.percentage =
            ((res.response.CheckProgress +
              res.response.NewProgress -
              res.response.noMasterWords.length) /
              (this.taksCheckWords + this.taskNewsWords)) *
            100;
          this.isStartNoMaster = 1;
        } else {
          this.studys = res.response.checkWords.concat(res.response.newWords);
          this.percentage =
            ((this.toDayCheck + this.toDayNew) /
              (this.taskNewsWords + this.taksCheckWords)) *
            100;
        }
        // this.isCompeleteTask = res.response.isCompelete == 1 ? true : false;

        console.log(this.studys);

        this.checktudys = res.response.taskCheckCount;
        this.newstudys = res.response.taskNewsCount;

        if (!this.check) {
          if (this.studys[0].isNew == 0) {
            this.toDayCheck = 1;
          } else {
            this.toDayNew = 1;
          }
        }
      } else {
        this.$message.error(res.msg);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.time);
  },
};
</script>
<style lang="less" scoped>
.vocabulary {
  min-height: 100vh;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px 0;
    div {
      width: 50%;
      span {
        display: block;
        text-align: center;
        &:first-child {
          color: #999999;
        }
        &:last-child {
        }
      }
    }
  }
  .cont {
    .danc {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      background-color: #fff;
      flex-wrap: wrap;
      min-height: 300px;
      padding: 30px 20px;
      div {
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
        line-height: 1;
        &:nth-child(1) {
          font-size: 40px;
          font-family: DIN;
          font-weight: 400;
        }
        &:nth-child(2) {
          color: #999999;
          margin-top: 30px;
        }
      }
    }
    .li {
      font-size: 15px;
      margin-top: 10px;
      padding: 0 15px 15px;
      color: #666;
    }
  }
  .title {
    line-height: 100%;
    padding: 15px 0;
    font-size: 15px;
    font-weight: bold;
  }
  .word {
    padding: 15px;
    .name {
      font-size: 16px;
      font-family: DIN;
      font-weight: bold;
      color: #3a78f9;
      line-height: 1.5;
    }
    .pronunciation {
      color: #999999;
      line-height: 1.5;
      margin: 10px 0 15px;
    }
    .notes {
      color: #999999;
      line-height: 1.5;
    }
  }
}

.icon_audio {
  width: 15px;
  vertical-align: text-bottom;
  margin: 0 5px;
}
.btn_que {
  div {
    min-width: 90px;
    cursor: pointer;
  }
  div + div {
    margin-left: 25px;
  }
}
</style>
